<template>
  <div class="list-container">
   <div class="vld-parent">
   <loading :active.sync="isLoading"
   :can-cancel="false"
   :is-full-page="fullPage"></loading>
  </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import { userService } from '../services/user_service';
  import { mapActions } from 'vuex';

  export default {
    name: 'SignIn',
    components: {
      Loading
    },
    data () {
      return {
        isLoading: false,
        fullPage: true
      };
    },
    computed: {
    },
    async mounted () {
      this.isLoading = true;
      const userDataSocialLogin = await userService.getLoginSocialInfo(this.$route.query);
      if (userDataSocialLogin) {
        await this.socialLogin({ userDataSocialLogin: userDataSocialLogin });
      }
    },
    methods: {
      ...mapActions('account', ['socialLogin', 'logout'])
    }
  };
</script>
